import { Grid } from '@mui/material';
import type {
  AddEstimateItemForm,
  GetEstimateResponsetInterface,
  PostEstimateItemRequest,
  Zone
} from 'api/types';
import CustomButton from 'components/NewLayout/Button';
import CustomDialog from 'components/NewLayout/Dialog';
import SearchableDropdown from 'components/NewLayout/Dropdown';
import TextField from 'components/NewLayout/Textfield';
import type { ChangeEvent } from 'react';
import { useReducer, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Validator from 'simple-react-validator';
import { getValidations, QUANTITY_VALIDATION_REGEX } from 'utils/index';

const initialAddEstimateItemObj = {
  item_name: '',
  quantity: '',
  zone: '',
  scene: ''
};

export default function AddEstimateItemModal({
  isLoading,
  isOpen,
  setOpen,
  estimateData,
  submitData
}: {
  isLoading: boolean;
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  estimateData: GetEstimateResponsetInterface;
  submitData: (data: PostEstimateItemRequest) => void;
}) {
  const [data, setData] = useState<AddEstimateItemForm>(initialAddEstimateItemObj);
  const [selectedZone, setSelectedZone] = useState<Zone | null>(null);
  const validator = useRef(new Validator(getValidations()));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (name === 'quantity') {
      if (QUANTITY_VALIDATION_REGEX.test(value)) {
        const totalChars = value.replace('.', '').length;
        if (totalChars <= 10) {
          setData((prevData) => ({
            ...prevData,
            [name]: value
          }));
        }
      }
    } else if (name === 'zone') {
      const zone = estimateData.zones.find((z) => z.zone_id === value) || null;
      setSelectedZone(zone);
      setData((prevData) => ({
        ...prevData,
        zone: value,
        scene: ''
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const submitEstimateItem = () => {
    if (validator.current.allValid()) {
      if (selectedZone?.scenes.length && !data.scene) {
        return toast.error('Scene is required', {
          position: 'top-center'
        });
      }
      const dataToSave: PostEstimateItemRequest = {
        estimate_id: estimateData.estimate_id,
        item_name: data.item_name,
        quantity: data.quantity,
        zone_id: data.zone,
        scene_id: data.scene
      };
      submitData(dataToSave);
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  return (
    <CustomDialog
      open={isOpen}
      title="Add Estimate Item"
      maxWidth="xs"
      loading={isLoading}
      closeDialog={() => setOpen(false)}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              labelText="Item Name"
              name="item_name"
              placeholder="Enter Item Name"
              value={data?.item_name || ''}
              onChange={handleChange}
              validator={validator}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              labelText="Quantity"
              name="quantity"
              placeholder="Enter Quantity"
              value={data?.quantity || ''}
              onChange={handleChange}
              validator={validator}
              isRequired={true}
              type="number"
              min="0"
            />
          </Grid>
          <Grid item xs={12}>
            <SearchableDropdown
              label="Zone"
              inputName="zone"
              options={estimateData.zones.map((zone) => ({
                id: zone.zone_id,
                value: zone.zone_name
              }))}
              value={data?.zone}
              onChange={handleChange}
              validator={validator}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SearchableDropdown
              label="Scene"
              inputName="scene"
              options={
                selectedZone?.scenes.map((scene) => ({
                  id: scene.scene_id,
                  value: scene.scene_name
                })) || []
              }
              value={data?.scene ?? ''}
              onChange={handleChange}
              disabled={!selectedZone?.scenes.length}
            />
          </Grid>
        </Grid>
      }
      actions={
        <>
          <CustomButton onClick={() => setOpen(false)} variant="outlined" label="Cancel" />
          <CustomButton
            onClick={submitEstimateItem}
            label="Add Estimate Item"
            disabled={isLoading}
          />
        </>
      }
    />
  );
}
