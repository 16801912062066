import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useCommonStore } from 'store/index';
import { tabTitle } from 'utils/index';
import QuantityAccordion from './components/QuantityAccordion';
import TitleHeader from './components/TitleHeader';
import { useNavigate } from 'react-router-dom';
import MaterialAccordion from './components/MaterialAccordion';
import LaborAccordion from './components/LaborAccordion';

export default function EstimateItemWorkspace() {
  const estimateItemData = window.history.state?.data;
  const setComponentHeading = useCommonStore((state) => state.setComponentHeading);
  const navigate = useNavigate();

  tabTitle('Estimate Item Workspace');

  useEffect(() => {
    setComponentHeading('Estimate Item Workspace');
  }, [setComponentHeading]);

  useEffect(() => {
    if (!estimateItemData?.estimate_item_id) {
      navigate('/job-estimation/estimate-workspace');
    }
  }, [estimateItemData, navigate]);

  return (
    estimateItemData?.estimate_item_id && (
      <Box>
        <TitleHeader title={estimateItemData?.estimate_item_name || 'No Item Name'} />
        <Box sx={{ marginY: '15px' }}>
          <QuantityAccordion />
        </Box>
        <Box sx={{ marginY: '15px' }}>
          <MaterialAccordion />
        </Box>
        <Box sx={{ marginY: '15px' }}>
          <LaborAccordion />
        </Box>
      </Box>
    )
  );
}
