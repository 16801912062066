import { Box, IconButton } from '@mui/material';
import { estimate, getAllCustomers, getAllUsers } from 'api/index';
import type {
  Customer,
  GetEstimateRequestInterface,
  GetEstimateResponsetInterface,
  PostEstimateItemRequest,
  User,
  Zone
} from 'api/types';
import DeleteIcon from 'assets/images/delete-icon.svg';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CreateCustomer from 'pages/AdminHub/CustomerWorkspace/components/CreateCustomer';
import { useEffect, useState } from 'react';
import { useCommonStore } from 'store/index';
import { roundToTwoDecimalPlaces, tabTitle } from 'utils/index';
import AddEstimateItemModal from './components/addEstimateItemModal';
import EstimateItems from './components/EstimateItems';
import EstimatesList from './components/EstimatesList';
import InitiateEstimateDrawer from './components/InitiateEstimateDrawer';
import NoDocuments from './components/NoDocuments';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'components/NewLayout/Button';

export interface PaginationDataType {
  pageNumber: string;
  pageSize: string;
  searchText: string;
}

export default function EstimateWorkspace() {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedEstimateData, setSelectedEstimateData] = useState<GetEstimateResponsetInterface>();
  const [estimatesList, setEstimatesList] = useState<any>([]);
  const [estimatesTotalCount, setEstimatesTotalCount] = useState<number>(0);
  const [estimatesListPaginationData, setEstimatesListPaginationData] =
    useState<PaginationDataType>({ pageNumber: '1', pageSize: '10', searchText: '' });
  const [isOpenInitiateEstimateDrawer, setInitiateEstimateDrawer] = useState<boolean>(false);
  const [isOpenCreateCustomerDrawer, setCreateCustomereDrawer] = useState<boolean>(false);
  const [allCustomers, setAllCustomers] = useState<DropdownOptionType[]>([]);
  const [allEstimators, setAllEstimators] = useState<DropdownOptionType[]>([]);
  const [estimateItemsList, setEstimateItemsList] = useState<any>([]);
  const [isOpenAddEstimateItemModal, setAddEstimateItemModal] = useState<boolean>(false);
  const [estimateItemsListPaginationData, setEstimateItemsListPaginationData] =
    useState<PaginationDataType>({ pageNumber: '1', pageSize: '10', searchText: '' });
  const [createJob, setCreateJob] = useState<string | null>(null);
  const [getFilterddata, setGetFilterdData] = useState<string | null>(null);
  const [getEstimate, setGetEstimate] = useState<string | null>(null);
  const [sliderValues, setSliderValues] = useState<{ [key: string]: number }>({});

  tabTitle('Estimate Master Workspace');
  const setComponentHeading = useCommonStore((state) => state.setComponentHeading);

  useEffect(() => {
    setComponentHeading('Estimate Master Workspace');
  }, []);

  useEffect(() => {
    const userRoles: any = localStorage.getItem('userRoles');
    const userRolesArr: string[] = userRoles?.split(',');
    if (userRolesArr.length === 1 && userRolesArr[0] === 'admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    setSelectedUser(localStorage.getItem('userId') || '');
  }, []);

  useEffect(() => {
    fetchEstimates();
    fetchEstimators();
  }, []);

  useEffect(() => {
    fetchEstimates();
  }, [estimatesListPaginationData, isAdmin, selectedUser]);

  const fetchEstimates = () => {
    try {
      if (typeof isAdmin === 'boolean' && typeof selectedUser === 'string') {
        setIsloading(true);
        const body: GetEstimateRequestInterface = {
          http_method: 'GET',
          data: {
            ...(!isAdmin && { userId: selectedUser }),
            ...estimatesListPaginationData
          }
        };

        estimate(body)
          .then((res) => {
            const sortedEstimates = res.data.map((estimate: GetEstimateResponsetInterface) => ({
              ...estimate,
              zones: estimate.zones
                ?.sort((a, b) => Number(a.zone_id) - Number(b.zone_id))
                ?.map((zone) => ({
                  ...zone,
                  scenes: zone.scenes?.sort((a, b) => Number(a.scene_id) - Number(b.scene_id))
                }))
            }));

            setEstimatesList(sortedEstimates);
            setEstimatesTotalCount(res.totalCount);
          })
          .catch((err) => {
            console.error('Error on fetching estimates', err);
          })
          .finally(() => {
            setIsloading(false);
          });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const fetchCustomers = async () => {
    try {
      const getCustomers = await getAllCustomers();
      const customerOptions: DropdownOptionType[] = getCustomers.map((item: Customer) => ({
        id: item.customer_id ?? '',
        value: item.customer_name ?? ''
      }));
      setAllCustomers(customerOptions);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const fetchEstimators = async () => {
    try {
      const getEstimators = await getAllUsers();
      const estimatorOptions: DropdownOptionType[] = getEstimators.data.map((item: User) => ({
        id: item.user_id ?? '',
        value: `${item.first_name} ${item.last_name}`
      }));
      setAllEstimators(estimatorOptions);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const postEstimateItem = async (data: PostEstimateItemRequest) => {
    try {
      setIsloading(true);
      setEstimateItemsList([
        ...estimateItemsList,
        {
          estimate_id: data.estimate_id,
          estimate_item_id: '123',
          action: (
            <IconButton color="error">
              <img src={DeleteIcon} />
            </IconButton>
          ),
          estimate_item_name: data.item_name,
          quantity: data.quantity,
          total: 'estimate-items-total-1',
          mtls: 'estimate-items-mtls-1',
          total_mtls_50: 'estimate-items-total_mtls_50-1',
          non_value: 'estimate-items-non_value-1',
          non_value_50: 'estimate-items-non_value_2-1',
          zone_id: data.zone_id,
          zone_name: selectedEstimateData?.zones.find((zone: Zone) => zone.zone_id === data.zone_id)
            ?.zone_name,
          scene_id: data?.scene_id ?? '',
          scene_name: selectedEstimateData?.zones
            .flatMap((zone: Zone) => zone.scenes)
            .find((scene) => scene.scene_id === data.scene_id)?.scene_name
        }
      ]);
      // Implement API call or logic to handle data submission
      setIsloading(false);
      setAddEstimateItemModal(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const EstimateItemLink = (row) => {
    const handleClick = (e) => {
      e.preventDefault();

      const serializableData = {
        estimate_item_id: row.estimate_item_id,
        estimate_item_name: row.estimate_item_name
      };

      const newTab = window.open('/job-estimation/estimate-item-workspace', '_blank');

      if (newTab) {
        newTab.onload = () => {
          newTab.history.replaceState(
            { data: serializableData },
            '',
            '/job-estimation/estimate-item-workspace'
          );
        };
      }
    };

    return (
      <span className="url" onClick={handleClick}>
        {row.estimate_item_name}
      </span>
    );
  };

  const estimatesListColumns = [
    {
      name: '',
      prop: 'select',
      data: (row) => (
        <input
          type="radio"
          name="rowSelect"
          onChange={() => setSelectedEstimateData(row)}
          checked={selectedEstimateData?.estimate_id == row.estimate_id}
          className="custom-radio"
        />
      )
    },
    {
      prop: 'estimate_id',
      name: 'Estimate ID',
      data: 'estimate_id'
    },
    {
      prop: 'job_name',
      name: 'Job Name',
      data: 'job_name'
    },
    {
      prop: 'job_type',
      name: 'Job Type',
      data: 'job_type'
    },
    {
      prop: 'total',
      name: 'Total',
      data: (row) => `$${roundToTwoDecimalPlaces(row.total ?? 0)}`
    },
    {
      prop: 'estimate_items',
      name: 'Estimate Items',
      data: (row) => `${row.estimate_items ?? 0}`
    },
    {
      prop: 'customer',
      name: 'Customer',
      data: (row) => row.customer.customer_name
    },
    {
      prop: 'assignee',
      name: 'Assignee',
      data: (row) => `${row.estimator.first_name} ${row.estimator.last_name}`
    },
    {
      prop: 'link_to_dropbox',
      name: 'Link To DropBox',
      data: () => (
        <a href="https://www.dropbox.com" target="_blank" rel="noopener noreferrer" className="url">
          Go To Dropbox
        </a>
      )
    }
  ];

  const estimateItemsListColumns = [
    {
      prop: 'action',
      name: 'Action',
      data: 'action'
    },
    {
      prop: 'estimate_item',
      name: 'Estimate Item',
      data: (row) => EstimateItemLink(row)
    },
    {
      prop: 'quantity',
      name: 'Quantity',
      data: 'quantity'
    },
    {
      prop: 'total',
      name: 'Total',
      data: 'total'
    },
    {
      prop: 'mtls',
      name: 'MTLS',
      data: 'mtls'
    },
    {
      prop: 'total_mtls_%',
      name: `Total MTLS: ${sliderValues['total_mtls_%'] || '0'}%`,
      data: 'total_mtls_%'
    },
    {
      prop: 'non_value',
      name: 'Non - Value',
      data: 'non_value'
    },
    {
      prop: 'non_value_mtls_%',
      name: `Non-Value MTLS: ${sliderValues['non_value_mtls_%'] || '0'}%`,
      data: 'non_value_mtls_%'
    },
    {
      prop: 'paint',
      name: 'Paint',
      data: 'paint'
    },
    {
      prop: 'fabrication_@',
      name: `Fabrication: @${sliderValues['fabrication_@'] || '0'}`,
      data: 'fabrication_@'
    },
    {
      prop: 'paint_@',
      name: `Paint: @${sliderValues['paint_@'] || '0'}`,
      data: 'paint_@'
    },
    {
      prop: 'media_@',
      name: `Media: @${sliderValues['media_@'] || '0'}`,
      data: 'media_@'
    },
    {
      prop: 'graphics_@',
      name: `Graphics: @${sliderValues['graphics_@'] || '0'}`,
      data: 'graphics_@'
    },
    {
      prop: 'pm_@',
      name: `PM: @${sliderValues['pm_@'] || '0'}`,
      data: 'pm_@'
    },
    {
      prop: 'design_@',
      name: `Design: @${sliderValues['design_@'] || '0'}`,
      data: 'design_@'
    },
    {
      prop: 'install_@',
      name: `Install: @${sliderValues['install_@'] || '0'}`,
      data: 'install_@'
    }
  ];

  const handleSliderChange = (id: string, value: number) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
  };

  const customerCreationCallBack = async () => {
    await fetchCustomers();
    setCreateCustomereDrawer(false);
    setInitiateEstimateDrawer(true);
  };

  const estimateCreationCallBack = () => {
    fetchEstimates();
  };

  return (
    <>
      <EstimatesList
        estimatesListColumns={estimatesListColumns}
        estimatesList={estimatesList}
        totalCount={estimatesTotalCount}
        setSearchStr={(val) => {
          setEstimatesListPaginationData({
            ...estimatesListPaginationData,
            searchText: val
          });
        }}
        isLoading={isLoading}
        setCreateJob={setCreateJob}
        setGetEstimate={setGetEstimate}
        setInitiateEstimateDrawer={setInitiateEstimateDrawer}
        setPaginationData={setEstimatesListPaginationData}
      />
      {selectedEstimateData ? (
        <>
          <EstimateItems
            loading={isLoading}
            setAddEstimateItemModal={setAddEstimateItemModal}
            estimateData={selectedEstimateData}
            columns={estimateItemsListColumns}
            items={estimateItemsList}
            setPaginationData={setEstimateItemsListPaginationData}
            handleSliderChange={handleSliderChange}
          />
        </>
      ) : (
        <Box sx={{ marginY: '15px' }}>
          <NoDocuments text={'Please Select Estimation from above to view more information'} />
        </Box>
      )}
      {isOpenInitiateEstimateDrawer && (
        <InitiateEstimateDrawer
          IsOpen={isOpenInitiateEstimateDrawer}
          setOpen={setInitiateEstimateDrawer}
          allCustomers={allCustomers}
          allEstimators={allEstimators}
          setCreateCustomereDrawer={setCreateCustomereDrawer}
          successCallBack={estimateCreationCallBack}
        />
      )}
      {isOpenCreateCustomerDrawer && (
        <CreateCustomer
          open={isOpenCreateCustomerDrawer}
          setOpen={setCreateCustomereDrawer}
          action="create"
          successCallBack={customerCreationCallBack}
          cancelCallBack={() => {
            setCreateCustomereDrawer(false);
            setInitiateEstimateDrawer(true);
          }}
        />
      )}
      {isOpenAddEstimateItemModal && selectedEstimateData && (
        <AddEstimateItemModal
          isLoading={isLoading}
          isOpen={isOpenAddEstimateItemModal}
          setOpen={setAddEstimateItemModal}
          estimateData={selectedEstimateData}
          submitData={postEstimateItem}
        />
      )}
    </>
  );
}
