import { Box, Typography } from '@mui/material';
import React from 'react';

const InfoBadge = ({ label, badgeValue }: { label: string; badgeValue: string | number }) => (
  <>
    <Box
      sx={{
        backgroundColor: '#4E4E4E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: '5px 15px',
        borderRadius: '5px',
        marginLeft: '5px'
      }}>
      <Typography
        sx={{
          color: '#fff',
          fontWeight: '500',
          marginRight: '5px',
          fontSize: '20px',
          fontFamily: 'Poppins'
        }}>
        {label}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          borderRadius: '8px',
          minWidth: '30px',
          height: '25px',
          backgroundColor: '#c32051',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '7px 10px',
          fontSize: '20px'
        }}>
        {badgeValue}
      </Typography>
    </Box>
  </>
);

export default InfoBadge;
