import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography
} from '@mui/material';
import CustomButton from 'components/NewLayout/Button';
import DataTable from 'components/NewLayout/Table';
import { useState } from 'react';
import type { PaginationDataType } from '..';
import InfoBadge from 'components/NewLayout/InfoBadge';

type ColumnType = {
  prop: string;
  name: string;
  data: string | ((row: any) => JSX.Element);
};

const AccordionTable = ({
  items,
  zoneName,
  sceneName,
  columns,
  loading,
  dataSource
}: {
  items: any[];
  zoneName: string;
  sceneName?: string;
  columns: ColumnType[];
  loading?: boolean;
  dataSource: (data: PaginationDataType) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      defaultExpanded
      sx={{ border: '1px solid #000', borderRadius: '5px', marginBottom: '15px' }}>
      <AccordionSummary>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: '10px'
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '24px',
                lineHeight: '30px',
                textAlign: 'left',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none'
              }}>
              {zoneName}
              {sceneName && ' |'}
            </Typography>
            {sceneName && (
              <Box>
                <CustomButton
                  onClick={() => console.log('Zone 2 Btn click')}
                  label={sceneName}
                  sx={{ borderRadius: '20px', paddingY: '3px', marginLeft: '10px' }}
                />
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <InfoBadge label="Total Items" badgeValue={items.length} />
            <Box>
              <IconButton onClick={handleToggle}>
                {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <DataTable
          items={items}
          columns={columns}
          loading={loading}
          totalCount={0}
          serverSidePagination
          dataSource={dataSource}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionTable;
