import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  IconButton,
  Typography
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import CustomButton from 'components/NewLayout/Button';
import DataTable from 'components/NewLayout/Table';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoBadge from 'components/NewLayout/InfoBadge';
import addIcon from 'assets/images/add_circle.svg';
const MaterialAccordion = () => {
  const [estimatesList, setEstimatesList] = useState<any>([]);
  const materialsListColumns = [
    {
      name: 'Non Value',
      prop: 'non_value',
      data: <Checkbox />
    },
    {
      name: 'Material Name',
      prop: 'material_name',
      data: 'material_name'
    },
    {
      name: 'Quantity',
      prop: 'quantity',
      data: 'quantity'
    },
    {
      name: 'Unit Cost',
      prop: 'unit_cost',
      data: 'unit_cost'
    },
    {
      name: 'Total',
      prop: 'total',
      data: 'total'
    }
  ];

  return (
    <>
      <Accordion className="mui-accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '0px'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: '500',
                    fontSize: '22px',
                    lineHeight: '30px',
                    textAlign: 'left',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none'
                  }}>
                  Materials
                </Typography>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: '1px solid #000' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '26px' }}>Fabrication</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <InfoBadge label="Material Total" badgeValue="$12,400" />
              <InfoBadge label="Non Value Total" badgeValue="$0" />
              <CustomButton
                sx={{ marginLeft: '5px' }}
                onClick={() => console.log('click')}
                label={
                  <>
                    <img src={addIcon} style={{ marginRight: '5px' }} /> Add New Row
                  </>
                }
              />
              <IconButton>
                <CachedIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <DataTable
              items={estimatesList}
              columns={materialsListColumns}
              totalCount={0}
              serverSidePagination
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MaterialAccordion;
