import React, { useRef, useEffect, useState } from 'react';

const EstimateItemCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [dragging, setDragging] = useState(false);
  const [zonePosition, setZonePosition] = useState({ x: 100, y: 100 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    const drawCanvas = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#eaeaea';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#ff8080';
      ctx.fillRect(zonePosition.x, zonePosition.y, 100, 50);
      ctx.font = '16px Arial';
      ctx.fillStyle = 'white';
      ctx.fillText('Zone 1', zonePosition.x + 25, zonePosition.y + 30);
    };

    drawCanvas();
  }, [zonePosition]);

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    const x = e.clientX - (rect?.left ?? 0);
    const y = e.clientY - (rect?.top ?? 0);

    if (
      x >= zonePosition.x &&
      x <= zonePosition.x + 100 &&
      y >= zonePosition.y &&
      y <= zonePosition.y + 50
    ) {
      setDragging(true);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!dragging) return;
    const rect = canvasRef.current?.getBoundingClientRect();
    const x = e.clientX - (rect?.left ?? 0);
    const y = e.clientY - (rect?.top ?? 0);

    setZonePosition({ x: x - 50, y: y - 25 });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  return (
    <canvas
      ref={canvasRef}
      width={500}
      height={300}
      style={{ border: '1px solid #ccc' }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    />
  );
};

export default EstimateItemCanvas;
