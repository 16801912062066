import { Box, Chip, Grid, Input, Tooltip, Typography } from '@mui/material';
import type { PostEstimateInterface, Zone } from 'api/types';
import CreateIcon from 'assets/images/create.svg';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import DrawerForm from 'components/NewLayout/DrawerForm';
import SearchableDropdown from 'components/NewLayout/Dropdown';
import Loader from 'components/NewLayout/Loader';
import CustomTextfield from 'components/NewLayout/Textfield';
import type { ChangeEvent } from 'react';
import { forwardRef, useImperativeHandle, useReducer, useRef, useState } from 'react';
import Validator from 'simple-react-validator';
import { getValidations } from 'utils/index';
import type { AdminHubChildComponentRef } from 'utils/types';
import ZoneHierarchy from './ZoneHierarchy';
import { estimate } from 'api/index';
import toast from 'react-hot-toast';

interface InitiateEstimateInterface {
  customer: string;
  estimator: string;
  job_name: string;
  job_start_date: string;
  job_due_date: string;
  zone_and_scene: string;
}

export default function InitiateEstimateDrawer({
  IsOpen,
  setOpen,
  allCustomers,
  allEstimators,
  setCreateCustomereDrawer,
  successCallBack
}: {
  IsOpen: boolean;
  setOpen: (status: boolean) => void;
  allCustomers: DropdownOptionType[];
  allEstimators: DropdownOptionType[];
  setCreateCustomereDrawer: (status: boolean) => void;
  successCallBack: () => void;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const childRef = useRef<AdminHubChildComponentRef>();

  return (
    <DrawerForm
      open={IsOpen}
      closeDrawer={() => setOpen(false)}
      heading="Initiate an Estimate"
      actions={
        <>
          <CustomButton variant="outlined" label="Discard" onClick={() => setOpen(false)} />
          <CustomButton
            label="Submit Request"
            onClick={() => {
              if (childRef.current) {
                childRef.current.submitForm();
              }
            }}
          />
        </>
      }>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography component="em" color="textSecondary">
            Enter the details below to initiate an estimate.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : (
            <InitiateEstimateForm
              ref={childRef}
              onComplete={() => {
                setOpen(false);
                successCallBack();
              }}
              setIsLoading={setIsLoading}
              allCustomers={allCustomers}
              allEstimators={allEstimators}
              setCreateCustomereDrawer={setCreateCustomereDrawer}
              setOpen={setOpen}
            />
          )}
        </Grid>
      </Grid>
    </DrawerForm>
  );
}

const initialObj = {
  customer: '',
  estimator: '',
  job_name: '',
  job_start_date: '',
  job_due_date: '',
  zone_and_scene: ''
};

const InitiateEstimateForm = forwardRef(
  (
    {
      setIsLoading,
      allCustomers,
      allEstimators,
      onComplete,
      setOpen,
      setCreateCustomereDrawer
    }: {
      setIsLoading: (val: boolean) => void;
      allCustomers: DropdownOptionType[];
      allEstimators: DropdownOptionType[];
      onComplete: () => void;
      setOpen: (status: boolean) => void;
      setCreateCustomereDrawer: (status: boolean) => void;
    },
    ref
  ) => {
    const [data, setData] = useState<InitiateEstimateInterface>(initialObj);
    const [zoneValue, setZoneValue] = useState('');
    const [scenesList, setScenesList] = useState<string[]>([]);
    const [currSceneValue, setCurrSceneValue] = useState('');
    const [editingZoneIndex, setZoneEditingIndex] = useState<number>(-1);
    const [editingSceneIndex, setSceneEditingIndex] = useState<number | null>(null);
    const [editingSceneValue, setSceneEditingValue] = useState('');
    const [zoneSceneList, setZoneSceneList] = useState<Zone[]>([]);
    const [zoneSceneDropDownList, setZoneSceneDropDownList] = useState<DropdownOptionType[]>([]);
    //
    const validator = useRef(new Validator(getValidations()));
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const handleKeyUp = (e) => {
      if (e.key === 'Enter' && currSceneValue.trim() !== '') {
        setScenesList((oldState) => [...oldState, currSceneValue.trim()]);
        setCurrSceneValue('');
      }
    };

    const handleChipEditStart = (index: number) => {
      setSceneEditingIndex(index);
      setSceneEditingValue(scenesList[index]);
    };

    const handleEditChange = (e) => {
      setSceneEditingValue(e.target.value);
    };

    const handleEditKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && editingSceneValue.trim() !== '' && editingSceneIndex !== null) {
        const updatedValues = [...scenesList];
        updatedValues[editingSceneIndex] = editingSceneValue.trim();
        setScenesList(updatedValues);
        setSceneEditingIndex(null);
        setSceneEditingValue('');
      } else if (e.key === 'Escape') {
        setSceneEditingIndex(null);
        setSceneEditingValue('');
      }
    };

    const handleEditBlur = () => {
      if (editingSceneValue.trim() !== '' && editingSceneIndex !== null) {
        const updatedValues = [...scenesList];
        updatedValues[editingSceneIndex] = editingSceneValue.trim();
        setScenesList(updatedValues);
      }
      setSceneEditingIndex(null);
      setSceneEditingValue('');
    };

    const handleDelete = (item, index) => {
      const arr = [...scenesList];
      arr.splice(index, 1);
      setScenesList(arr);
    };

    useImperativeHandle(ref, () => ({
      submitForm() {
        if (validator.current.allValid()) {
          const sanitizedZoneSceneList = zoneSceneList.map((zone) => ({
            zone_name: zone.zone_name,
            scenes: zone.scenes
              .filter((scene) => typeof scene.scene_name === 'string')
              .map((scene) => ({
                scene_name: scene.scene_name!
              }))
          }));

          const finalZoneSceneList = sanitizedZoneSceneList.map((zone) => ({
            ...zone,
            scenes: zone.scenes.length > 0 ? zone.scenes : []
          }));

          const dataToInsert: PostEstimateInterface = {
            http_method: 'POST',
            data: {
              customer_id: data.customer,
              user_id: data.estimator,
              job_name: data.job_name,
              job_type: finalZoneSceneList.some((zone) => zone.scenes.length > 0)
                ? 'Large'
                : 'Small',
              job_start_date: data.job_start_date,
              job_due_date: data.job_due_date,
              zone_scene_list: finalZoneSceneList
            }
          };

          estimate(dataToInsert)
            .then((res) => {
              onComplete();
              console.log('res', res);
              toast.success(res.message, {
                position: 'top-center'
              });
            })
            .catch((err) => {
              console.error('err', err);
              toast.error(err, {
                position: 'top-center'
              });
            })
            .finally(() => {
              setOpen(false);
            });

          console.log('dataToInsert', dataToInsert);
        } else {
          validator.current.showMessages();
          forceUpdate();
        }
      }
    }));

    const handleChange = ({
      target: { name, value }
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (name === 'zone') {
        setZoneValue(value);
      } else {
        setData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      }
    };

    const addZone = () => {
      setZoneSceneList((prevList) => {
        const updatedList = [
          ...prevList,
          {
            zone_name: zoneValue,
            scenes: scenesList.map((item) => ({
              scene_name: item
            }))
          }
        ];
        updateZoneSceneDropdownList(updatedList);
        return updatedList;
      });

      setZoneValue('');
      setCurrSceneValue('');
      setScenesList([]);
      setZoneEditingIndex(-1);
    };

    const updateEditZone = () => {
      if (editingZoneIndex > -1) {
        setZoneSceneList((prevList) => {
          const updatedList = [...prevList];
          updatedList[editingZoneIndex] = {
            zone_name: zoneValue,
            scenes: scenesList.map((item) => ({
              scene_name: item
            }))
          };
          updateZoneSceneDropdownList(updatedList);
          return updatedList;
        });

        setZoneValue('');
        setCurrSceneValue('');
        setScenesList([]);
        setZoneEditingIndex(-1);
        setData((prevData) => ({
          ...prevData,
          zone_and_scene: ''
        }));
      }
    };

    const updateZoneSceneDropdownList = (updatedZoneSceneList) => {
      const newList: DropdownOptionType[] = [];
      updatedZoneSceneList.forEach((zone) => {
        if (zone.scenes?.length) {
          zone.scenes.forEach((scene) => {
            if (scene) {
              newList.push({
                id: scene.scene_name
                  ? `${zone.zone_name}-${scene.scene_name}`
                  : `${zone.zone_name}`,
                value: scene.scene_name
                  ? `${zone.zone_name}-${scene.scene_name}`
                  : `${zone.zone_name}`
              });
            }
          });
        } else {
          newList.push({
            id: zone.zone_name,
            value: zone.zone_name
          });
        }
      });
      setZoneSceneDropDownList(newList);
    };

    const onClickEditZone = (index: number) => {
      setZoneEditingIndex(index);
      setZoneValue(zoneSceneList[index].zone_name);
      if (zoneSceneList[index].scenes.length) {
        setScenesList(
          zoneSceneList[index].scenes
            .map((item) => item.scene_name)
            .filter((sceneName): sceneName is string => sceneName !== undefined)
        );
      }
    };

    const onClickDeleteZone = (index: number) => {
      setZoneSceneList((prevList) => {
        const updatedList = prevList.filter((_, i) => i !== index);
        updateZoneSceneDropdownList(updatedList);
        return updatedList;
      });
      setData((prevData) => ({
        ...prevData,
        zone_and_scene: ''
      }));
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SearchableDropdown
              label="Customer"
              inputName="customer"
              options={allCustomers}
              value={data.customer}
              onChange={handleChange}
              validator={validator}
              required
            />
            <Tooltip title="Create Customer">
              <img
                onClick={() => {
                  setOpen(false);
                  setCreateCustomereDrawer(true);
                }}
                src={CreateIcon}
                className="img-fluid link-icon"
                alt="Create Icon"
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchableDropdown
            label={'Estimator'}
            inputName="estimator"
            options={allEstimators}
            value={data.estimator}
            onChange={handleChange}
            validator={validator}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextfield
            labelText="Job Name"
            name="job_name"
            placeholder="Job Name"
            value={data.job_name}
            onChange={handleChange}
            maxlength={25}
            validator={validator}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextfield
            labelText="Job Start Date"
            name={`job_start_date`}
            value={data.job_start_date}
            onChange={handleChange}
            type="date"
            size="medium"
            validator={validator}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextfield
            labelText="Job Due Date"
            name={`job_due_date`}
            value={data.job_due_date}
            onChange={handleChange}
            type="date"
            size="medium"
            validator={validator}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchableDropdown
            label={'Zone & Scene'}
            inputName="zone_and_scene"
            options={zoneSceneDropDownList}
            value={data.zone_and_scene ?? ''}
            onChange={handleChange}
            validator={validator}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            component="fieldset"
            sx={{
              border: '1px solid #ccc',
              borderRadius: '5px',
              position: 'relative',
              padding: 3,
              paddingTop: 4,
              zIndex: 0,
              minHeight: '300px',
              backgroundColor: 'white'
            }}>
            <Typography
              component="span"
              sx={{
                fontWeight: 'bold',
                position: 'absolute',
                top: '-10px',
                left: '10px',
                backgroundColor: 'white',
                zIndex: 22
              }}>
              Project Structure
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  labelText="Zone"
                  name={`zone`}
                  value={zoneValue}
                  onChange={handleChange}
                  type="text"
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center',
                    gap: '8px',
                    width: '100%',
                    flexWrap: 'wrap',
                    border: '2px solid lightgray',
                    borderRadius: '4px',
                    flexDirection: 'column'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    {scenesList.map((item, index) =>
                      editingSceneIndex === index ? (
                        <Input
                          key={index}
                          value={editingSceneValue}
                          onChange={handleEditChange}
                          onKeyDown={handleEditKeyDown}
                          onBlur={handleEditBlur}
                          autoFocus
                          sx={{ padding: '6px', marginLeft: '10px', width: '6rem' }}
                        />
                      ) : (
                        <Chip
                          size="medium"
                          onDelete={() => handleDelete(item, index)}
                          label={item}
                          key={index}
                          onClick={() => handleChipEditStart(index)}
                          sx={{ marginTop: '4px', marginLeft: '4px' }}
                        />
                      )
                    )}
                  </Box>
                  <Input
                    value={currSceneValue}
                    onChange={(e) => setCurrSceneValue(e.target.value)}
                    onKeyDown={handleKeyUp}
                    placeholder="Press Enter to add Scene"
                    sx={{ padding: '6px', marginLeft: '10px' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'end', mb: '25px' }}>
                <CustomButton
                  label={editingZoneIndex > -1 ? 'Update' : 'Add'}
                  disabled={!zoneValue}
                  onClick={() => (editingZoneIndex > -1 ? updateEditZone() : addZone())}
                  sx={{ padding: '5px', marginLeft: '8px' }}
                />
              </Grid>
              {zoneSceneList.map((zone, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <Box sx={{ marginY: '10px' }}>
                    <ZoneHierarchy
                      index={index}
                      zoneName={zone.zone_name}
                      scenes={zone.scenes
                        .map((scene) => scene.scene_name)
                        .filter((name): name is string => name !== undefined)}
                      onEdit={(index) => onClickEditZone(index)}
                      onDelete={(index) => onClickDeleteZone(index)}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }
);
InitiateEstimateForm.displayName = 'InitiateEstimateForm';
