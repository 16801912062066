import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  forwardRef,
  useImperativeHandle
} from 'react';
import type { ChangeEvent } from 'react';
import type { Customer } from 'api/types';
import { createCustomer, updateCustomer } from 'api/index';
import toast from 'react-hot-toast';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import {
  ALPHA_NUMERIC_DASH_REGEX,
  EMAIL_VALIDATION_REGEX,
  getValidations,
  validatePhoneNumber,
  WEBSITE_VALIDATION_REGEX
} from 'utils/index';
import GooglePlacesAutocompleteComp from 'components/inputs/GooglePlacesAutocomplete';
import Validator from 'simple-react-validator';
import TextField from 'components/NewLayout/Textfield';
import type { AdminHubChildComponentRef } from 'utils/types';
import DrawerForm from 'components/NewLayout/DrawerForm';
import CustomButton from 'components/NewLayout/Button';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useAdminWorkspaceStore, useCustomerStore } from 'store/index';
import { descriptionMaxLength } from 'utils/constants';

export default function CreateCustomer({
  open,
  setOpen,
  action,
  allCustomer,
  successCallBack,
  cancelCallBack
}: {
  open: boolean;
  setOpen: (status: boolean) => void;
  action: 'create' | 'update';
  allCustomer?: Customer[];
  successCallBack?: () => void;
  cancelCallBack?: () => void;
}) {
  const childRef = useRef<AdminHubChildComponentRef>();
  return (
    <DrawerForm
      open={open}
      closeDrawer={() => {
        setOpen(false);
        cancelCallBack && cancelCallBack();
      }}
      heading={`${action === 'create' ? 'Create' : 'Update'}  A Customer`}
      actions={
        <>
          <CustomButton
            variant="outlined"
            label="Discard"
            onClick={() => {
              setOpen(false);
              cancelCallBack && cancelCallBack();
            }}
          />
          <CustomButton
            label="Submit Request"
            onClick={() => {
              if (childRef.current) {
                childRef.current.submitForm();
              }
            }}
          />
        </>
      }>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography component="em" color="textSecondary">
            Enter the details below to create a new customer.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomerForm
            action={action}
            ref={childRef}
            onComplete={() => {
              setOpen(false);
              successCallBack && successCallBack();
            }}
            allCustomer={allCustomer}
          />
        </Grid>
      </Grid>
    </DrawerForm>
  );
}

const initialCustomerObj = {
  customer_name: '',
  phone: '',
  email: '',
  country: '',
  state: '',
  city: '',
  postal_code: '',
  address_1: '',
  address_2: '',
  notes: '',
  website: ''
};
const CustomerForm = forwardRef(
  (
    {
      action,
      onComplete,
      allCustomer
    }: {
      action: 'update' | 'create';
      onComplete: () => void;
      allCustomer?: Customer[];
    },
    ref
  ) => {
    const [data, setData] = useState<Customer>(initialCustomerObj);
    const validator = useRef(new Validator(getValidations()));
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const customer = useAdminWorkspaceStore((state) => state.selectedCustomer);
    const updateStoreCustomer = useCustomerStore((state) => state.updateCustomer);
    const [showSuggest, setShowSuggest] = useState<boolean>(false);
    const [nameSuggestion, setNameSuggestions] = useState<string[]>([]);
    let i = 0;

    const handleSuggestions = (suggestion: string) => {
      i = 0;
      setData({ ...data, customer_name: suggestion });
      setShowSuggest(false);
    };

    useEffect(() => {
      if (customer) {
        setData(customer);
      }
    }, [customer]);

    useImperativeHandle(ref, () => ({
      submitForm() {
        const dataToSave = { ...data };

        if (!dataToSave.email && !dataToSave.phone) {
          toast.error('Please provide either an Email or a Phone Number.', {
            position: 'top-center'
          });
          return;
        }

        if (dataToSave.email && !EMAIL_VALIDATION_REGEX.test(dataToSave.email ?? '')) {
          toast.error('Invalid email', {
            position: 'top-center'
          });
          return;
        }

        if (dataToSave.phone && !validatePhoneNumber(dataToSave.phone)) {
          toast.error('Invalid Phone Number', {
            position: 'top-center'
          });
          return;
        }

        if (
          dataToSave.website?.length &&
          !WEBSITE_VALIDATION_REGEX.test(dataToSave.website ?? '')
        ) {
          toast.error('Invalid website', {
            position: 'top-center'
          });
          return;
        }
        if (validator.current.allValid()) {
          if (action === 'create') {
            const loadingToast = toast.loading('Adding...', {
              position: 'top-center'
            });

            createCustomer(dataToSave)
              .then((res: { message: string }) => {
                toast.dismiss(loadingToast);
                if (res.message.toLowerCase().includes('success')) {
                  toast.success('Customer added successfully!', {
                    position: 'top-center'
                  });
                  onComplete();
                }
              })
              .catch((err) => {
                toast.dismiss(loadingToast);
                const { response } = err;

                if (response?.status === 409) {
                  setShowSuggest(true);
                  const suggestions = Array.from({ length: 3 }, () => {
                    const randomNum = Math.floor(1000 + Math.random() * 9000);
                    return `${dataToSave.customer_name}${randomNum}`;
                  });
                  setNameSuggestions(suggestions);
                }
              });
          } else {
            if (data.customer_id) {
              const {
                created_at,
                created_by,
                customer_id,
                is_active,
                last_updated_by,
                updated_at,
                updatedby,
                uuid,
                ...dataToSaveTrimmed
              } = dataToSave;
              const loadingToast = toast.loading('Updating...', {
                position: 'top-center'
              });
              updateCustomer(dataToSaveTrimmed, data?.uuid ?? '')
                .then((res: { message: string }) => {
                  toast.dismiss(loadingToast);
                  if (res.message === 'Customer updated successfully!') {
                    updateStoreCustomer({ customer_id, ...dataToSaveTrimmed });
                    toast.success('Customer Updated successfully!', {
                      position: 'top-center'
                    });
                    onComplete();
                  }
                })
                .catch((err) => {
                  toast.dismiss(loadingToast);
                  console.log(err);
                });
            }
          }
        } else {
          validator.current.showMessages();
          forceUpdate();
        }
      }
    }));

    const handleChange = ({
      target: { name, value }
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (name == 'customer_name' && (!ALPHA_NUMERIC_DASH_REGEX.test(value) || value.length > 40)) {
        toast.error(
          'Special characters are not allowed and maximum length should not more then 40 characters',
          {
            position: 'top-center'
          }
        );
        return;
      }
      setData({ ...data, [name]: value === 'true' ? true : value === 'false' ? false : value });
    };

    const updateAddress = ({ city, country, state, postalCode, addressLine1 }: any) => {
      const updatedData = {
        ...data,
        city: city ?? '',
        country: country ?? '',
        state: state ?? '',
        postal_code: postalCode ?? '',
        address_1: addressLine1 ?? ''
      };

      setData(updatedData);
    };

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              labelText="Customer Name"
              name="customer_name"
              placeholder="Enter Customer Name"
              value={data.customer_name}
              onChange={handleChange}
              isRequired={true}
              disabled={action === 'update' && !customer}
              validator={validator}
            />

            {showSuggest ? (
              <Box>
                {nameSuggestion.map((names, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    className="rounded-pill"
                    sx={{ padding: '2px, 3px', marginX: '5px' }}
                    onClick={() => handleSuggestions(names)}>
                    {names}
                  </Button>
                ))}
              </Box>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              labelText="Email Address"
              name="email"
              placeholder="Enter Email"
              value={data.email}
              onChange={handleChange}
              disabled={action === 'update' && !customer}
              isRequired={!data.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneNumberInput
              label="Phone Number"
              name="phone"
              handleChange={handleChange}
              disabled={action === 'update' && !customer}
              value={data.phone}
              isRequired={!data.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              labelText="Website"
              name="website"
              placeholder="Enter Website"
              value={data.website}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <GooglePlacesAutocompleteComp
              validator={validator}
              labelText="Address"
              name="address_1"
              value={data.address_1 || ''}
              onChange={handleChange}
              isRequired={true}
              getAddressComponent={(address) => updateAddress(address)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              labelText="Additional Notes"
              name="notes"
              placeholder="Add Notes"
              value={data.notes}
              onChange={handleChange}
              isTextarea
              disabled={action === 'update' && !customer}
              maxlength={descriptionMaxLength}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);

CustomerForm.displayName = 'CustomerForm';
