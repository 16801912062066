import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useCommonStore } from 'store/index';

export default function CustomSnackbar() {
  const { open, message, type } = useCommonStore((state) => state.snackBarData);
  const setSnackBarData = useCommonStore((state) => state.setSnackBarData);

  const handleClose = () => {
    setSnackBarData({
      open: false,
      message: '',
      type: 'success'
    });
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert
        onClose={handleClose}
        severity={type}
        variant="filled"
        sx={{ width: '100%', padding: '20px' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
