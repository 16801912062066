import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import 'assets/css/style.css';
import { useState } from 'react';

interface DataItem {
  id: string;
  name: string;
  value?: number;
}

const Slider = ({
  data,
  handleSliderChange
}: {
  data: DataItem[];
  handleSliderChange: (id: string, value: number) => void;
}) => {
  const [sliderData, setSliderData] = useState(data);

  const handleValueChange = (id: string, value: number) => {
    setSliderData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, value } : item))
    );
    handleSliderChange(id, value);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Splide
        style={{ overflow: 'hidden' }}
        options={{
          trimSpace: 'move',
          pagination: false,
          perPage: 3,
          arrows: true,
          autoWidth: true,
          type: 'loop',
          resetProgress: false
        }}
        className="splide-custom-arrows">
        {sliderData.map((item) => (
          <SplideSlide key={item.id} style={{ padding: '20px 0px', textAlign: 'start' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                backgroundColor: '#D1D5DB',
                borderRadius: '25px',
                padding: '12px 24px',
                marginLeft: '30px',
                color: '#1F2937'
              }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {item.name}
              </Typography>
              <TextField
                type="number"
                value={item.value || ''}
                onChange={(e) => handleValueChange(item.id, Number(e.target.value))}
                size="small"
                InputProps={{
                  endAdornment: item.id.includes('%') ? (
                    <InputAdornment
                      position="end"
                      sx={{
                        marginLeft: '-5px',
                        fontWeight: 'bold',
                        color: '#1F2937'
                      }}>
                      %
                    </InputAdornment>
                  ) : null
                }}
                sx={{
                  width: '70px',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    height: '30px',
                    '& input': {
                      padding: '1px',
                      textAlign: 'center'
                    },
                    '& fieldset': {
                      borderColor: 'darkblue'
                    },
                    '&:hover fieldset': {
                      borderColor: 'darkblue'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'darkblue'
                    }
                  }
                }}
              />
            </Box>
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default Slider;
