import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';

const ZoneHierarchy = ({
  index,
  zoneName,
  scenes,
  onEdit,
  onDelete
}: {
  index: number;
  zoneName: string;
  scenes: string[];
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}) => (
  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: { xs: 'space-between', sm: 'space-around' },
        flexWrap: 'wrap',
        gap: 1,
        marginTop: '-15px'
      }}>
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: '14px', sm: '18px' },
          textAlign: { xs: 'center', sm: 'left' }
        }}>
        {zoneName}
      </Typography>

      <Box sx={{ display: 'flex' }}>
        <IconButton color="secondary" sx={{ padding: '2px' }} onClick={() => onEdit(index)}>
          <EditIcon sx={{ fontSize: { xs: '12px', sm: '20px' } }} />
        </IconButton>
        <IconButton color="primary" sx={{ padding: '2px' }} onClick={() => onDelete(index)}>
          <DeleteOutlineIcon sx={{ fontSize: { xs: '12px', sm: '20px' } }} />
        </IconButton>
      </Box>
    </Box>

    <Box>
      <Box
        sx={{
          borderTop: '1px solid red',
          width: '50px'
        }}
      />
    </Box>

    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderLeft: '1px solid red',
          height: 'auto'
        }}>
        {scenes.map((scene, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'start',
              marginBottom: '15px'
            }}>
            <Box
              sx={{
                borderTop: '1px solid red',
                width: '50px',
                marginRight: '10px',
                paddingBottom: '12px'
              }}
            />
            <Box
              sx={{
                width: 'auto',
                height: '20px',
                color: '#fff',
                display: 'flex',
                alignItems: 'center'
              }}>
              <p
                style={{
                  backgroundColor: '#335d87',
                  padding: '0 8px',
                  fontSize: '16px',
                  borderRadius: '5px'
                }}>
                {scene}
              </p>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
);

export default ZoneHierarchy;
