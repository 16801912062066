import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography
} from '@mui/material';
import stylusNoteIcon from 'assets/images/stylus_note.svg';
import CustomButton from 'components/NewLayout/Button';
import DataTable from 'components/NewLayout/Table';
import { useState } from 'react';
import Canvas from './Canvas';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoBadge from 'components/NewLayout/InfoBadge';
const QuantityAccordion = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showCanva, setShowCanva] = useState<boolean>(true);
  const [estimatesList, setEstimatesList] = useState<any>([]);
  const estimatesListColumns = [
    {
      name: 'Action',
      prop: 'action',
      data: <Button>Del</Button>
    },
    {
      name: 'Quantity',
      prop: 'quantity',
      data: 'quantity'
    },
    {
      name: 'Width',
      prop: 'width',
      data: 'width'
    },
    {
      name: 'Length',
      prop: 'length',
      data: 'length'
    },
    {
      name: 'Sq. Ft.',
      prop: 'sq_ft',
      data: 'sq_ft'
    },
    {
      name: 'Total Estimated Cost',
      prop: 'total_estimated_cost',
      data: 'total_estimated_cost'
    }
  ];

  return (
    <>
      <Accordion className="mui-accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '0px'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: '500',
                    fontSize: '22px',
                    lineHeight: '30px',
                    textAlign: 'left',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none'
                  }}>
                  Quantity
                </Typography>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: '1px solid #000' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '26px' }}>Fabrication</Typography>
            </Box>
            <Box>
              <InfoBadge label="Total Cost" badgeValue="$20" />
            </Box>
          </Box>
          <Box>
            <DataTable
              items={estimatesList}
              columns={estimatesListColumns}
              totalCount={0}
              serverSidePagination
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginY: '10px'
              }}>
              <CustomButton
                sx={{
                  border: '1px dashed #335d87',
                  backgroundColor: '#fff',
                  color: '#335d87',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': { backgroundColor: '#fff' }
                }}
                onClick={() => setShowCanva(!showCanva)}
                label={
                  <>
                    <img src={stylusNoteIcon} style={{ marginRight: '5px' }} />
                    <span>Move Canvas Here</span>
                  </>
                }
              />
            </Box>
            <Box
              sx={{
                marginY: '10px',
                backgroundColor: '#f4f4f4'
              }}>
              <Box
                sx={{
                  padding: '10px 20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  Estimate Item Canvas
                </Typography>
                <Button sx={{ padding: '5px' }}>
                  <CancelOutlinedIcon />
                </Button>
              </Box>
              {showCanva ? (
                <Box sx={{ textAlign: 'center' }}>
                  <Canvas />
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default QuantityAccordion;
