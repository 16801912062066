import { Box, Typography } from '@mui/material';
import type { GetEstimateResponsetInterface, Zone } from 'api/types';
import PaperContainer from 'components/PaperContainer';
import type { PaginationDataType } from '..';
import AccordionTable from './Accordion';
import EstimateItemSearchHeader from './EstimateItemSearchHeader';
import Slider from './Slider';
import NoDocuments from './NoDocuments';
import CustomButton from 'components/NewLayout/Button';

type ColumnType = {
  prop: string;
  name: string;
  data: string | ((row: any) => JSX.Element);
};

const EstimateItems = ({
  loading,
  setAddEstimateItemModal,
  columns,
  items,
  estimateData,
  setPaginationData,
  handleSliderChange
}: {
  loading: boolean;
  setAddEstimateItemModal: (val: boolean) => void;
  columns: ColumnType[];
  items: [];
  estimateData: GetEstimateResponsetInterface;
  setPaginationData: (data: PaginationDataType) => void;
  handleSliderChange: (id: string, value: number) => void;
}) => {
  const sliderNames: { id: string; name: string }[] = [
    {
      id: 'total_mtls_%',
      name: 'Total Mtls: '
    },
    {
      id: 'non_value_mtls_%',
      name: 'Non-Value Mtls: '
    },
    {
      id: 'fabrication_@',
      name: 'Fabrication: '
    },
    {
      id: 'paint_@',
      name: 'Paint: '
    },
    {
      id: 'media_@',
      name: 'Media: '
    },
    {
      id: 'graphics_@',
      name: 'Graphics: '
    },
    {
      id: 'pm_@',
      name: 'PM: '
    },
    {
      id: 'design_@',
      name: 'Design: '
    },
    {
      id: 'install_@',
      name: 'Install: '
    }
  ];
  return (
    <>
      <Box sx={{ marginY: '20px' }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '30px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none'
          }}>
          {`${estimateData.job_name} - Estimate Items`}
        </Typography>
      </Box>
      {items.length === 0 ? (
        <Box sx={{ marginY: '15px' }}>
          <NoDocuments
            text={'Add Items to Estimate'}
            component={
              <CustomButton
                label={'Add Items'}
                onClick={() => setAddEstimateItemModal(true)}
                sx={{ padding: '3px 6px 3px 6px', mt: '8px' }}
              />
            }
          />
        </Box>
      ) : (
        <PaperContainer>
          <Box sx={{ mb: '10px' }}>
            <EstimateItemSearchHeader
              onChange={() => console.log('input is working')}
              items={items}
              setAddEstimateItemModal={setAddEstimateItemModal}
            />
          </Box>
          <Box>
            <Slider
              data={sliderNames}
              handleSliderChange={(id: string, value: number) => handleSliderChange(id, value)}
            />
          </Box>
          {estimateData.zones.map((zone: Zone, zoneIndex) => {
            const zoneFilteredItems = items.filter((item: any) => item.zone_id === zone.zone_id);
            return zone.scenes && zone.scenes.length > 0
              ? zone.scenes.map((scene, sceneIndex) => {
                  const sceneFilteredItems = zoneFilteredItems.filter(
                    (item: { scene_id: string; scene_name: string }) =>
                      item.scene_id === scene.scene_id
                  );
                  if (sceneFilteredItems.length === 0) return null;
                  return (
                    <AccordionTable
                      key={`${zoneIndex}-${sceneIndex}`}
                      zoneName={`Zone ${zoneIndex + 1} - ${zone.zone_name}`}
                      sceneName={scene.scene_name}
                      columns={columns}
                      items={sceneFilteredItems}
                      loading={loading}
                      dataSource={setPaginationData}
                    />
                  );
                })
              : zoneFilteredItems.length > 0 && (
                  <AccordionTable
                    key={`zone-${zoneIndex}`}
                    zoneName={`Zone ${zoneIndex + 1} - ${zone.zone_name}`}
                    columns={columns}
                    items={zoneFilteredItems}
                    loading={loading}
                    dataSource={setPaginationData}
                  />
                );
          })}
        </PaperContainer>
      )}
    </>
  );
};

export default EstimateItems;
