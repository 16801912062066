import { Box } from '@mui/material';
import type { User } from 'api/types';
import DataTable from 'components/NewLayout/Table';
import PaperContainer from 'components/PaperContainer';
import type { PaginationDataType } from '..';
import Analytics from './Analytics';
import EstimateSearchHeader from './EstimateSearchHeader';

type ColumnType = (
  | {
      name: string;
      prop: string;
      data: (row: any) => JSX.Element;
    }
  | {
      prop: string;
      name: string;
      data: string;
    }
)[];

export default function EstimatesList({
  isLoading,
  estimatesListColumns,
  estimatesList,
  totalCount,
  setPaginationData,
  setSearchStr,
  setCreateJob,
  setGetEstimate,
  setInitiateEstimateDrawer
}: {
  isLoading: boolean;
  estimatesListColumns: ColumnType;
  estimatesList: any;
  totalCount: number;
  setPaginationData: (data: PaginationDataType) => void;
  setSearchStr: (value: string) => void;
  setCreateJob: (data: string | null) => void;
  setGetEstimate: (data: string | null) => void;
  setInitiateEstimateDrawer: (status: boolean) => void;
}) {
  const getEstimateData = (data: string) => {
    console.log(data);
    setGetEstimate(data);
  };

  const getCreateJobData = (data: string) => {
    console.log(data);
    setCreateJob(data);
  };

  const handleSearchData = (e) => {
    setSearchStr(e.target.value);
  };

  return (
    <>
      <Box className="active-inactive-admin-ws-div" sx={{ marginBottom: '20px', flexWrap: 'wrap' }}>
        <Box sx={{ marginX: '10px', marginY: '5px' }}>
          <Analytics title={'Open Estimate'} value={2} backgroundColor={'#c32051'} />
        </Box>
        <Box sx={{ marginX: '10px', marginY: '5px' }}>
          <Analytics title={'Completed Estimates'} value={2} backgroundColor={'#335d87'} />
        </Box>
      </Box>
      <PaperContainer>
        <Box>
          <EstimateSearchHeader
            sendEstimateData={getEstimateData}
            sendCreateJobData={getCreateJobData}
            onChange={handleSearchData}
            setInitiateEstimateDrawer={setInitiateEstimateDrawer}
          />
        </Box>
        <br />
        <DataTable<User>
          items={estimatesList}
          columns={estimatesListColumns}
          loading={isLoading}
          totalCount={totalCount}
          serverSidePagination
          dataSource={(pageNumber, pageSize, searchText) =>
            setPaginationData({ pageNumber, pageSize, searchText })
          }
        />
      </PaperContainer>
    </>
  );
}
