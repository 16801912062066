import stylusNoteIcon from 'assets/images/stylus_note.svg';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import CustomButton from 'components/NewLayout/Button';
import CachedIcon from '@mui/icons-material/Cached';
import DataTable from 'components/NewLayout/Table';
import { useState } from 'react';
import addIcon from 'assets/images/add_circle.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoBadge from 'components/NewLayout/InfoBadge';
const LaborAccordion = () => {
  const [estimatesList, setEstimatesList] = useState<any>([]);
  const fabricationColumns = [
    {
      name: 'Material Name',
      prop: 'material_name',
      data: 'material_name'
    },
    {
      name: 'Quantity',
      prop: 'quantity',
      data: 'quantity'
    },
    {
      name: 'Hours Per',
      prop: 'hours_per',
      data: 'hours_per'
    },
    {
      name: 'Per Unit Hours',
      prop: 'per_unit_hours',
      data: 'per_unit_hours'
    },
    {
      name: 'Per Unit Days',
      prop: 'per_unit_days',
      data: 'per_unit_days'
    },
    {
      name: 'Total',
      prop: 'total',
      data: 'total'
    }
  ];
  const paintColumns = [
    {
      name: 'Paint Task',
      prop: 'paint_task',
      data: 'paint_task'
    },
    {
      name: 'Quantity',
      prop: 'quantity',
      data: 'quantity'
    },
    {
      name: 'Hours Per',
      prop: 'hours_per',
      data: 'hours_per'
    },
    {
      name: 'Per Unit Hours',
      prop: 'per_unit_hours',
      data: 'per_unit_hours'
    },
    {
      name: 'Per Unit Days',
      prop: 'per_unit_days',
      data: 'per_unit_days'
    },
    {
      name: 'Total',
      prop: 'total',
      data: 'total'
    },
    {
      name: 'Install',
      prop: 'install',
      data: 'install'
    }
  ];

  return (
    <Accordion className="mui-accordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0px'
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: '500',
                  fontSize: '22px',
                  lineHeight: '30px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none'
                }}>
                Labor
              </Typography>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '1px solid #000' }}>
        {/* 1st section  */}
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '26px' }}>Fabrication</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <InfoBadge label="Material Total" badgeValue="$12400" />
              <InfoBadge label="Per Unit Hours" badgeValue="$0.00" />
              <InfoBadge label="Per Unit Days" badgeValue="$0.00" />
              <CustomButton
                sx={{ marginLeft: '5px' }}
                onClick={() => console.log('click')}
                label={
                  <>
                    <img src={addIcon} style={{ marginRight: '5px' }} /> Add New Row
                  </>
                }
              />
              <IconButton>
                <CachedIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <DataTable
              items={estimatesList}
              columns={fabricationColumns}
              totalCount={0}
              serverSidePagination
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: '10px'
            }}>
            <CustomButton
              sx={{
                border: '1px dashed #335d87',
                backgroundColor: '#fff',
                color: '#335d87',
                display: 'flex',
                alignItems: 'center',
                '&:hover': { backgroundColor: '#fff' }
              }}
              onClick={() => console.log('click')}
              label={
                <>
                  <img src={stylusNoteIcon} style={{ marginRight: '5px' }} />
                  <span>Move Canvas Here</span>
                </>
              }
            />
          </Box>
        </Box>
        {/* 2nd section  */}
        <Box sx={{ marginTop: '40px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '26px' }}>Paint</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <InfoBadge label="Paint Total" badgeValue="$12400" />
              <InfoBadge label="Per Unit Hours" badgeValue="$0.00" />
              <InfoBadge label="Per Unit Days" badgeValue="$0.00" />
              <CustomButton
                sx={{ marginLeft: '5px' }}
                onClick={() => console.log('click')}
                label={
                  <>
                    <img src={addIcon} style={{ marginRight: '5px' }} /> Add New Row
                  </>
                }
              />
              <IconButton>
                <CachedIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <DataTable
              items={estimatesList}
              columns={paintColumns}
              totalCount={0}
              serverSidePagination
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LaborAccordion;
